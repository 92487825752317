import { Input, Label, PixelBackground } from '@onbeam/ui';
import useENS from '../../hooks/useENS';
import { getEtherscanLink } from '../../utils';
import { useCallback, ChangeEventHandler } from 'react';
import useConnectedChain from '../../hooks/useConnectedChain';
import { link } from '@onbeam/styled-system/patterns';

export default function AddressInputPanel({
  id,
  value,
  onChange,
}: {
  id?: string;
  // the typed string value
  value: string;
  // triggers whenever the typed value changes
  onChange: (value: string) => void;
}) {
  const { chainId } = useConnectedChain();

  const { address, loading, name } = useENS(value);

  const handleInput = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const input = event.target.value;
      const withoutSpaces = input.replace(/\s+/g, '');
      onChange(withoutSpaces);
    },
    [onChange],
  );

  const error = Boolean(value.length > 0 && !loading && !address);

  return (
    <PixelBackground id={id}>
      {address && chainId && (
        <a
          className={link()}
          target="_blank"
          rel="noopener noreferrer"
          href={getEtherscanLink(chainId, name ?? address, 'address')}
        >
          (view on block explorer)
        </a>
      )}

      <Input
        label={<Label>recipient</Label>}
        size="sm"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        placeholder="wallet address or ens name"
        pattern="^(0x[a-fA-F0-9]{40})$"
        onChange={handleInput}
        value={value}
        hasError={error}
      />
    </PixelBackground>
  );
}
