import {
  Icon,
  Loader,
  Modal,
  TransactionDetailsBox,
  TransactionConfirmationModal,
} from '@onbeam/ui';
import { getEtherscanLink } from '../../utils';
import { AutoColumn, ColumnCenter } from '../Column';

import { ChainId } from '@beamswap/sdk';
import React from 'react';
import { ArrowUpCircle } from 'react-feather';
import { ErrorIcon } from '@onbeam/icons';
import { css } from '@onbeam/styled-system/css';
import { flex, link, text } from '@onbeam/styled-system/patterns';
import useConnectedChain from '../../hooks/useConnectedChain';

function ConfirmationPendingContent({
  pendingText,
}: {
  pendingText: string;
}) {
  return (
    <>
      <p>{pendingText}</p>

      <p className={text({ textStyle: 'sm', color: 'mono.300' })}>
        confirm this transaction in your wallet
      </p>
    </>
  );
}

function TransactionSubmittedContent({
  chainId,
  hash,
}: {
  hash: string | undefined;
  chainId: ChainId;
}) {
  return (
    <div className={css({ w: 'full' })}>
      <TransactionDetailsBox className={css({ py: '7' })}>
        <ColumnCenter>
          <ArrowUpCircle strokeWidth={0.5} size={18} color="#ebbcff" />
        </ColumnCenter>
        <AutoColumn $gap="12px" $justify={'center'}>
          <AutoColumn $justify={'center'}>
            <AutoColumn $gap="12px" $justify={'center'}>
              <p>transaction submitted</p>
            </AutoColumn>
            {chainId && hash && (
              <a
                className={link()}
                target="_blank"
                rel="noopener noreferrer"
                href={getEtherscanLink(chainId, hash, 'transaction')}
              >
                view on block explorer
              </a>
            )}
          </AutoColumn>
        </AutoColumn>
      </TransactionDetailsBox>
    </div>
  );
}

export function ConfirmationModalContent({
  bottomContent,
  topContent,
}: {
  topContent: () => React.ReactNode;
  bottomContent: () => React.ReactNode;
}) {
  return (
    <div className={flex({ direction: 'column', gap: '4' })}>
      <TransactionDetailsBox>{topContent()}</TransactionDetailsBox>
      {bottomContent()}
    </div>
  );
}

export function TransactionErrorContent({
  message,
}: {
  message: string;
}) {
  return (
    <div className={css({ w: 'full' })}>
      <TransactionDetailsBox>
        <ColumnCenter>
          <Icon size={24}>
            <ErrorIcon />
          </Icon>
        </ColumnCenter>
        <AutoColumn $gap="12px" $justify={'center'}>
          <AutoColumn $justify={'center'}>
            <AutoColumn $gap="12px" $justify={'center'}>
              <p
                color="red.400"
                style={{
                  overflowWrap: 'anywhere',
                }}
              >
                {message}
              </p>
            </AutoColumn>
          </AutoColumn>
        </AutoColumn>
      </TransactionDetailsBox>
    </div>
  );
}

interface ConfirmationModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  hash: string | undefined;
  content: () => React.ReactNode;
  attemptingTxn: boolean;
  pendingText: string;
}

export default function TransactionConfirmation({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
}: ConfirmationModalProps) {
  const { chainId } = useConnectedChain();

  if (!chainId) return null;

  // confirmation screen
  return (
    <>
      {attemptingTxn ? (
        <TransactionConfirmationModal
          icon={<Loader />}
          title="confirm transaction"
          open={isOpen}
          onOpenChange={onDismiss}
          size="lg"
        >
          <ConfirmationPendingContent pendingText={pendingText} />
        </TransactionConfirmationModal>
      ) : hash ? (
        <Modal
          title="transaction submitted"
          open={isOpen}
          onOpenChange={onDismiss}
        >
          <TransactionSubmittedContent chainId={chainId} hash={hash} />
        </Modal>
      ) : (
        content()
      )}
    </>
  );
}
