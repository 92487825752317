import { css } from '@onbeam/styled-system/css';
import { vstack } from '@onbeam/styled-system/patterns';
import { Card } from '@onbeam/ui';
import { PropsWithChildren } from 'react';
import { Footer } from '../Footer';
import { CookieConsentModal } from '@onbeam/features';

export const AppLayout = ({ children }: PropsWithChildren) => (
  <>
    <main
      className={vstack({
        justify: 'center',
        align: 'center',
        gap: '2',
        flex: '1',
        py: '12',

        md: {
          py: '28',
        },
      })}
    >
      <Card size="lg" className={css({ w: 'full' })}>
        {children}
      </Card>
    </main>
    <Footer />
    <CookieConsentModal
      consentDomain={
        process.env.NODE_ENV === 'development' ? 'localhost' : '.onbeam.com'
      }
    />
  </>
);
