import { AutoColumn, ColumnCenter } from '../../components/Column';
import { FindPoolTabs } from '../../components/NavigationTabs';
import { MinimalPositionCard } from '../../components/PositionCard';
import Row from '../../components/Row';
import { PairState, usePair } from '../../data/Reserves';
import { usePairAdder } from '../../state/user/hooks';
import { useTokenBalance } from '../../state/wallet/hooks';
import { currencyId } from '../../utils/currencyId';
import { Currency, DEV, JSBI, TokenAmount } from '@beamswap/sdk';
import { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from '../../assets/svg/plus.svg';
import { useAccount } from 'wagmi';
import { ConnectHandler } from '../../layout/ConnectHandler';
import { IconButton, TransactionDetailsBox } from '@onbeam/ui';
import { css } from '@onbeam/styled-system/css';
import { Link as ReactLink } from 'react-router-dom';
import { CurrencyModal } from '../../components/CurrencyModal';
import { flex, link, text } from '@onbeam/styled-system/patterns';
import { Dots } from '../../components/Dots';

export default function PoolFinder() {
  const { address } = useAccount();

  const [currency0, setCurrency0] = useState<Currency | undefined>(DEV);
  const [currency1, setCurrency1] = useState<Currency | undefined>(undefined);

  const [pairState, pair] = usePair(
    currency0 ?? undefined,
    currency1 ?? undefined,
  );
  const addPair = usePairAdder();

  useEffect(() => {
    if (pair) {
      addPair(pair);
    }
  }, [pair, addPair]);

  const validPairNoLiquidity: boolean =
    pairState === PairState.NOT_EXISTS ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.raw, JSBI.BigInt(0)) &&
        JSBI.equal(pair.reserve1.raw, JSBI.BigInt(0)),
    );

  const position: TokenAmount | undefined = useTokenBalance(
    address ?? undefined,
    pair?.liquidityToken,
  );
  const hasPosition = Boolean(
    position && JSBI.greaterThan(position.raw, JSBI.BigInt(0)),
  );

  const prerequisiteMessage = (
    <TransactionDetailsBox className={css({ py: '11' })}>
      <p className={css({ textAlign: 'center' })}>
        {!address
          ? 'connect to a wallet to find pools'
          : 'select a token to find your liquidity'}
      </p>
    </TransactionDetailsBox>
  );

  return (
    <ConnectHandler>
      <FindPoolTabs />
      <AutoColumn $gap="md">
        <Row>
          <div
            className={flex({
              w: '[calc(100% / 3)]',
              justifyContent: 'flex-start',
            })}
          >
            <CurrencyModal
              currency={currency0}
              onCurrencySelect={setCurrency0}
            />
          </div>

          <div
            className={flex({
              w: '[calc(100% / 3)]',
              justifyContent: 'center',
            })}
          >
            <IconButton as="div" className={css({ pointerEvents: 'none' })}>
              <PlusIcon />
            </IconButton>
          </div>

          <div
            className={flex({
              w: '[calc(100% / 3)]',
              justifyContent: 'flex-end',
            })}
          >
            <CurrencyModal
              currency={currency1}
              onCurrencySelect={setCurrency1}
            />
          </div>
        </Row>

        {hasPosition && (
          <ColumnCenter
            style={{
              justifyItems: 'center',
              backgroundColor: '',
              padding: '12px 0px',
              borderRadius: '12px',
            }}
          >
            <p className={css({ textAlign: 'center' })}>pool found!</p>
          </ColumnCenter>
        )}

        {currency0 && currency1 ? (
          pairState === PairState.EXISTS ? (
            hasPosition && pair ? (
              <MinimalPositionCard pair={pair} />
            ) : (
              <TransactionDetailsBox className={css({ py: '11' })}>
                <AutoColumn $gap="sm" $justify="center">
                  <p className={text({ textAlign: 'center' })}>
                    you don’t have liquidity in this pool yet
                  </p>
                  <ReactLink
                    className={link({ align: 'center' })}
                    to={`/add/${currencyId(currency0)}/${currencyId(
                      currency1,
                    )}`}
                  >
                    <p>add liquidity</p>
                  </ReactLink>
                </AutoColumn>
              </TransactionDetailsBox>
            )
          ) : validPairNoLiquidity ? (
            <TransactionDetailsBox className={css({ py: '11' })}>
              <AutoColumn $gap="sm" $justify="center">
                <p className={text({ textAlign: 'center' })}>no pool found</p>
                <ReactLink
                  className={link()}
                  to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                >
                  create pool
                </ReactLink>
              </AutoColumn>
            </TransactionDetailsBox>
          ) : pairState === PairState.INVALID ? (
            <TransactionDetailsBox className={css({ py: '11' })}>
              <AutoColumn $gap="sm" $justify="center">
                <p className={text({ textAlign: 'center' })}>invalid pair</p>
              </AutoColumn>
            </TransactionDetailsBox>
          ) : pairState === PairState.LOADING ? (
            <TransactionDetailsBox className={css({ py: '11' })}>
              <AutoColumn $gap="sm" $justify="center">
                <p className={text({ textAlign: 'center' })}>
                  <Dots>loading</Dots>
                </p>
              </AutoColumn>
            </TransactionDetailsBox>
          ) : null
        ) : (
          prerequisiteMessage
        )}
      </AutoColumn>
    </ConnectHandler>
  );
}
