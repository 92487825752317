import { Button, CheckboxButton, Icon, Modal } from '@onbeam/ui';
import useConnectedChain from '../../hooks/useConnectedChain';
import { getEtherscanLink } from '../../utils';
import { AutoColumn } from '../Column';
import CurrencyLogo from '../CurrencyLogo';
import { AutoRow } from '../Row';
import { Token } from '@beamswap/sdk';
import { useCallback, useState } from 'react';
import { ErrorIcon } from '@onbeam/icons';
import { hstack, link } from '@onbeam/styled-system/patterns';
import { formatAddress } from '@onbeam/utils';

interface TokenWarningCardProps {
  token?: Token;
}

function TokenWarningCard({ token }: TokenWarningCardProps) {
  const { chainId } = useConnectedChain();

  if (!token) return null;

  return (
    <AutoRow gap="6px">
      <AutoColumn $gap="24px">
        <CurrencyLogo currency={token} size={'16px'} />
        <div> </div>
      </AutoColumn>
      <AutoColumn $gap="10px" $justify="flex-start">
        <p>
          {token?.name && token.symbol && token.name !== token.symbol
            ? `${token.name} (${token.symbol})`
            : token.name || token.symbol}{' '}
        </p>
        {chainId && (
          <a
            className={link()}
            target="_blank"
            rel="noopener noreferrer"
            href={getEtherscanLink(chainId, token.address, 'token')}
          >
            {formatAddress(token.address)} (View on block explorer)
          </a>
        )}
      </AutoColumn>
    </AutoRow>
  );
}

export default function TokenWarningModal({
  isOpen,
  tokens,
  onConfirm,
}: {
  isOpen: boolean;
  tokens: Token[];
  onConfirm: () => void;
}) {
  const [understandChecked, setUnderstandChecked] = useState(false);
  const toggleUnderstand = useCallback(
    () => setUnderstandChecked((uc) => !uc),
    [],
  );

  return (
    <Modal open={isOpen} title="token warning">
      <AutoColumn $gap="lg">
        <AutoRow gap="6px">
          <Icon>
            <ErrorIcon />
          </Icon>
          <p>token imported</p>
        </AutoRow>
        <p>
          Anyone can create an ERC20 token on Ethereum with <em>any</em> name,
          including creating fake versions of existing tokens and tokens that
          claim to represent projects that do not have a token.
        </p>
        <p>
          This interface can load arbitrary tokens by token addresses. Please
          take extra caution and do your research when interacting with
          arbitrary ERC20 tokens.
        </p>
        <p>
          If you purchase an arbitrary token,{' '}
          <strong>you may be unable to sell it back.</strong>
        </p>
        {tokens.map((token) => {
          return <TokenWarningCard key={token.address} token={token} />;
        })}

        <div className={hstack({ align: 'center', gap: '4' })}>
          <Button
            as={CheckboxButton}
            size="lg"
            onChange={toggleUnderstand}
            checked={!understandChecked}
          >
            I understand
          </Button>

          <Button
            disabled={!understandChecked}
            size="lg"
            onClick={() => {
              onConfirm();
            }}
          >
            continue
          </Button>
        </div>
      </AutoColumn>
    </Modal>
  );
}
