import AddressInputPanel from '../../components/AddressInputPanel';
import { AutoColumn } from '../../components/Column';
import CurrencyInputPanel from '../../components/CurrencyInputPanel';
import { AutoRow, RowBetween } from '../../components/Row';
import TokenWarningModal from '../../components/TokenWarningModal';
import { AdvancedSwapDetails } from '../../components/swap/AdvancedSwapDetails';
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal';
import { TradePriceRow } from '../../components/swap/TradePriceRow';
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee';
import { SwapCallbackError } from '../../components/swap/styleds';
import { useCurrency } from '../../hooks/Tokens';
import store from '../../state';
import {
  ApprovalState,
  useApproveCallbackFromTrade,
} from '../../hooks/useApproveCallback';
import { useSwapCallback } from '../../hooks/useSwapCallback';
import useToggledVersion, { Version } from '../../hooks/useToggledVersion';
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback';
import { useSetSettingsMenu } from '../../state/application/hooks';
import { Field } from '../../state/swap/actions';
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../state/swap/hooks';
import {
  useExpertModeManager,
  useUserDeadline,
  useUserSlippageTolerance,
} from '../../state/user/hooks';

import { maxAmountSpend } from '../../utils/maxAmountSpend';
import {
  computeTradePriceBreakdown,
  warningSeverity,
} from '../../utils/prices';
import { Currency, CurrencyAmount, JSBI, Token, Trade } from '@beamswap/sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConnectButton } from '../../components/ConnectButton';
import { useAccount } from 'wagmi';
import useConnectedChain from '../../hooks/useConnectedChain';
import { ConnectHandler } from '../../layout/ConnectHandler';
import { Button, IconButton, TransactionDetailsRow } from '@onbeam/ui';
import { DownIcon, SwapIcon } from '@onbeam/icons';
import { css } from '@onbeam/styled-system/css';
import { link } from '@onbeam/styled-system/patterns';

export default function Swap() {
  const { t } = useTranslation();
  const loadedUrlParams = useDefaultsFromURLSearch();

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ];
  const [dismissTokenWarning, setDismissTokenWarning] =
    useState<boolean>(false);
  const urlLoadedTokens: Token[] = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]?.filter(
        (c): c is Token => c instanceof Token,
      ) ?? [],
    [loadedInputCurrency, loadedOutputCurrency],
  );
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
  }, []);

  const { isSupported } = useConnectedChain();
  const { address } = useAccount();

  // for expert mode
  const setSettingsMenu = useSetSettingsMenu();
  const [isExpertMode] = useExpertModeManager();

  // get custom setting values for user
  const [deadline] = useUserDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue,
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const toggledVersion = useToggledVersion();
  const trade = showWrap
    ? undefined
    : {
        [Version.v2]: v2Trade,
      }[toggledVersion];

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput],
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput],
  );

  const showImportedTokenWarning = useMemo(() => {
    if (urlLoadedTokens.length === 0) return false;

    const key = Object.keys(store.getState().lists.byUrl)[0];
    const list = store.getState().lists.byUrl[key];

    if (!list.current?.tokens) return false;

    // get first key
    const isInList = list.current.tokens.some(
      (token) =>
        token.address.toLowerCase() ===
        urlLoadedTokens[0].address.toLowerCase(),
    );

    if (isInList) {
      return false;
    }

    return urlLoadedTokens.length > 0 && !dismissTokenWarning;
  }, [urlLoadedTokens, dismissTokenWarning]);

  // modal and loading
  const [
    { showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
    setSwapState,
  ] = useState<{
    showConfirm: boolean;
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0)),
  );
  const noRoute = !route;

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage,
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval]);

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT],
  );

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient,
  );

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

  const handleSwap = useCallback(() => {
    if (
      priceImpactWithoutFee &&
      !confirmPriceImpactWithoutFee(priceImpactWithoutFee)
    ) {
      return;
    }
    if (!swapCallback) {
      return;
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm,
      swapErrorMessage: undefined,
      txHash: undefined,
    });
    swapCallback()
      .then((hash) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: undefined,
          txHash: hash,
        });
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        });
      });
  }, [tradeToConfirm, priceImpactWithoutFee, showConfirm, swapCallback]);

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false);

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({
      showConfirm: false,
      tradeToConfirm,
      attemptingTxn,
      swapErrorMessage,
      txHash,
    });
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '');
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapErrorMessage,
      txHash,
      attemptingTxn,
      showConfirm,
    });
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash]);

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
    },
    [onCurrencySelection],
  );

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact());
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) =>
      onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection],
  );

  return (
    <>
      <TokenWarningModal
        isOpen={showImportedTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      <ConnectHandler>
        <div id="swap-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <AutoColumn $gap={'md'}>
            <CurrencyInputPanel
              label={
                independentField === Field.OUTPUT && !showWrap && trade
                  ? 'from (estimated)'
                  : 'from'
              }
              value={formattedAmounts[Field.INPUT]}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
            />
            <AutoColumn $justify="space-between">
              <AutoRow
                justify={isExpertMode ? 'space-between' : 'center'}
                style={{ padding: '0 1rem' }}
              >
                <IconButton
                  onClick={() => {
                    setApprovalSubmitted(false); // reset 2 step UI for approvals
                    onSwitchTokens();
                  }}
                >
                  <SwapIcon />
                </IconButton>
                {recipient === null && !showWrap && isExpertMode ? (
                  <button
                    type="button"
                    id="add-recipient-button"
                    onClick={() => onChangeRecipient('')}
                    className={link({ cursor: 'pointer' })}
                  >
                    + add a send (optional)
                  </button>
                ) : null}
              </AutoRow>
            </AutoColumn>
            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label={
                independentField === Field.INPUT && !showWrap && trade
                  ? 'to (estimated)'
                  : 'to'
              }
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              id="swap-currency-output"
            />

            {recipient !== null && !showWrap ? (
              <>
                <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                  <IconButton>
                    <DownIcon />
                  </IconButton>
                  <button
                    type="button"
                    id="remove-recipient-button"
                    onClick={() => onChangeRecipient(null)}
                    className={link()}
                  >
                    - remove send
                  </button>
                </AutoRow>
                <AddressInputPanel
                  id="recipient"
                  value={recipient}
                  onChange={onChangeRecipient}
                />
              </>
            ) : null}

            {!showWrap && (
              <AdvancedSwapDetails trade={trade}>
                {!!trade && (
                  <TradePriceRow
                    price={trade?.executionPrice}
                    showInverted={showInverted}
                    setShowInverted={setShowInverted}
                  />
                )}

                <TransactionDetailsRow
                  label="slippage tolerance"
                  details={{
                    label: `${allowedSlippage / 100}%`,
                    onClick: () => setSettingsMenu(true),
                  }}
                />
              </AdvancedSwapDetails>
            )}
          </AutoColumn>

          <div className={css({ mt: '4' })}>
            {!address || !isSupported ? (
              <ConnectButton />
            ) : showWrap ? (
              <Button
                size="lg"
                disabled={Boolean(wrapInputError)}
                onClick={onWrap}
              >
                {wrapInputError ??
                  (wrapType === WrapType.WRAP
                    ? 'wrap'
                    : wrapType === WrapType.UNWRAP
                      ? 'unwrap'
                      : null)}
              </Button>
            ) : noRoute && userHasSpecifiedInputOutput ? (
              <Button size="lg" disabled>
                {t('insufficientLiquidityForThisTrade')}
              </Button>
            ) : showApproveFlow ? (
              <RowBetween style={{ gap: 10 }}>
                <Button
                  size="lg"
                  onClick={approveCallback}
                  isLoading={approval === ApprovalState.PENDING}
                  loadingMessage="approving"
                  disabled={
                    approval !== ApprovalState.NOT_APPROVED || approvalSubmitted
                  }
                >
                  {approvalSubmitted && approval === ApprovalState.APPROVED
                    ? 'approved'
                    : 'approve'}
                </Button>
                <Button
                  size="lg"
                  onClick={() => {
                    if (isExpertMode) {
                      handleSwap();
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined,
                      });
                    }
                  }}
                  id="swap-button"
                  disabled={
                    !isValid ||
                    approval !== ApprovalState.APPROVED ||
                    (priceImpactSeverity > 3 && !isExpertMode)
                  }
                  hasLed
                  ledOn={approval === ApprovalState.APPROVED}
                  // error={isValid && priceImpactSeverity > 2}
                >
                  {priceImpactSeverity > 3 && !isExpertMode
                    ? 'price impact high'
                    : `swap${priceImpactSeverity > 2 ? ' anyway' : ''}`}
                </Button>
              </RowBetween>
            ) : (
              <Button
                size="lg"
                onClick={() => {
                  if (isExpertMode) {
                    handleSwap();
                  } else {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      showConfirm: true,
                      txHash: undefined,
                    });
                  }
                }}
                id="swap-button"
                disabled={
                  !isValid ||
                  (priceImpactSeverity > 3 && !isExpertMode) ||
                  !!swapCallbackError
                }
                // error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
              >
                {swapInputError
                  ? swapInputError
                  : priceImpactSeverity > 3 && !isExpertMode
                    ? 'price impact too high'
                    : `swap${priceImpactSeverity > 2 ? ' anyway' : ''}`}
              </Button>
            )}
            {isExpertMode && swapErrorMessage ? (
              <SwapCallbackError error={swapErrorMessage} />
            ) : null}
          </div>
        </div>
      </ConnectHandler>
    </>
  );
}
