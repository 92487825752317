import Web3ReactManager from '../components/Web3ReactManager';
import { AppDispatch, AppState } from '../state';
import { acceptListUpdate } from '../state/lists/actions';
import { useSelectedListUrl } from '../state/lists/hooks';
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader';
import AddLiquidity from './AddLiquidity';
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './AddLiquidity/redirects';
import Pool from './Pool';
import PoolFinder from './PoolFinder';
import RemoveLiquidity from './RemoveLiquidity';
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects';
import Swap from './Swap';
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects';
import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { cx } from '@onbeam/styled-system/css';
import { AppBackground } from '@onbeam/ui';
import { vstack } from '@onbeam/styled-system/patterns';
import Popups from '../components/Popups';
import { Header } from '../layout/Header';
import { customScrollbar } from '@onbeam/styled-system/recipes';
import { AppLayout } from '../layout/AppLayout';
import { getGtmScript } from '@onbeam/utils';

import '../styles/globals.css';
import '../styles/index.css';

const GTM_ID = process.env.REACT_APP_GTM_ID;

const App = () => {
  // handle token list update if necessary
  const dispatch = useDispatch<AppDispatch>();
  const listsByUrl = useSelector<AppState, AppState['lists']['byUrl']>(
    (state) => state.lists.byUrl,
  );
  const selectedListUrl = useSelectedListUrl();

  // Initialise GTM after mount
  useEffect(() => {
    const existingScript = document.getElementById('gtag');
    if (!GTM_ID || !!existingScript) return;

    const script = document.createElement('script');
    script.id = 'gtag';
    script.async = true;
    script.innerHTML = getGtmScript(GTM_ID);
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (selectedListUrl) {
      const { pendingUpdate: pending } = listsByUrl[selectedListUrl];

      if (pending) {
        try {
          dispatch(acceptListUpdate(selectedListUrl));
        } catch (_e) {
          /* */
        }
      }
    }
  }, [selectedListUrl, listsByUrl, dispatch]);

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <Route component={DarkModeQueryParamReader} />
        <div
          className={cx(
            customScrollbar(),
            vstack({
              minH: '[100vh]',
            }),
          )}
        >
          <AppBackground />
          <Header />
          <Popups />
          <Web3ReactManager>
            <Switch>
              <AppLayout>
                <Route exact strict path="/swap" component={Swap} />
                <Route
                  exact
                  strict
                  path="/swap/:outputCurrency"
                  component={RedirectToSwap}
                />
                <Route
                  exact
                  strict
                  path="/send"
                  component={RedirectPathToSwapOnly}
                />
                <Route exact strict path="/find" component={PoolFinder} />
                <Route exact strict path="/pool" component={Pool} />
                <Route
                  exact
                  strict
                  path="/create"
                  component={RedirectToAddLiquidity}
                />
                <Route exact path="/add" component={AddLiquidity} />
                <Route
                  exact
                  path="/add/:currencyIdA"
                  component={RedirectOldAddLiquidityPathStructure}
                />
                <Route
                  exact
                  path="/add/:currencyIdA/:currencyIdB"
                  component={RedirectDuplicateTokenIds}
                />
                <Route
                  exact
                  strict
                  path="/remove/:tokens"
                  component={RedirectOldRemoveLiquidityPathStructure}
                />
                <Route
                  exact
                  strict
                  path="/remove/:currencyIdA/:currencyIdB"
                  component={RemoveLiquidity}
                />
                <Route component={RedirectPathToSwapOnly} />
              </AppLayout>
            </Switch>
          </Web3ReactManager>
        </div>
      </HashRouter>
    </Suspense>
  );
};

export default App;
